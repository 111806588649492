import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { ReactSVG } from 'react-svg'

import useContentful from '@common/utils/useContentful'
import { CONTENTFUL_CASHBACK_COLLECTION_ENTRY_ID } from '@common/constants'
import { MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'

const contentfulQuery = `
  query {
    cashbackMerchantCollection(id: "${CONTENTFUL_CASHBACK_COLLECTION_ENTRY_ID}") {
      merchantsCollection {
        items {
          title
          description
          offerDescription
          image1 {
            url
          }
          image2 {
            url
          }
          phoneNumber
          address
          url
          logo {
            url
          }
          backgroundImage {
            url
          }
          webImage1 {
            url
            description
          }
          webImage2 {
            url
            description
          }
          webGradient
        }
      }
    }
  }
`

const useCashbackRetailers = () => {
  const { contentfulData, contentfulError } = useContentful({ query: contentfulQuery })
  const isTablet = useMediaQuery({ query: `(max-width: ${MAX_WIDTH_BREAKPOINTS.MEDIUM_LARGE}px)` })

  const [cashbackRetailers, setCashbackRetailers] = useState(null)

  useEffect(() => {
    const getImages = item => {
      const images = []

      const image1 = isTablet
        ? (
          <ReactSVG
            src={item?.image1?.url}
            beforeInjection={(svg) => {
              svg.setAttribute('preserveAspectRatio', 'none')
            }}
          />
          )
        : <img src={item?.webImage1?.url} alt={item?.webImage1?.description} />
      const image2 = isTablet
        ? (
          <ReactSVG
            src={item?.image2?.url}
            beforeInjection={(svg) => {
              svg.setAttribute('preserveAspectRatio', 'none')
            }}
          />
          )
        : <img src={item?.webImage2?.url} alt={item?.webImage2?.description} />

      if (image1) {
        images.push(image1)
      }

      if (image2) {
        images.push(image2)
      }

      return images
    }

    const retailers = contentfulData?.cashbackMerchantCollection?.merchantsCollection?.items?.map(item => (
      {
        key: item?.title?.toLowerCase()?.split(' ')?.join('-'),
        name: item?.title,
        details: item?.description,
        logo: item?.logo?.url,
        background: {
          color: item?.webGradient,
          image: item?.backgroundImage?.url,
        },
        url: item?.url,
        address: item?.address,
        phone: item?.phoneNumber,
        offerDescription: item?.offerDescription,
        images: getImages(item),
      }
    )) || null

    if (retailers?.length) {
      const hiddenGymIndex = retailers.findIndex(item => item?.key === 'the-hidden-gym')

      if (hiddenGymIndex !== -1) {
        const item = retailers[hiddenGymIndex]
        retailers.splice(hiddenGymIndex, 1)
        retailers.unshift(item)
      }
    }

    setCashbackRetailers(retailers)
  }, [contentfulData?.cashbackMerchantCollection, isTablet])

  return {
    cashbackRetailersData: cashbackRetailers,
    cashbackRetailersError: contentfulError,
  }
}

export default useCashbackRetailers
