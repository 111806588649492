import React, { useState } from 'react'
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'

import TransferForm from './TransferForm'
import TransferReview from './TransferReview'
import TransferSuccess from './TransferSuccess'
import ProtectedRoute from '@routing/ProtectedRoute'
import Button from '@shared/components/button/Button'
import Modal from '@shared/components/modal/Modal'
import ModalBody from '@shared/components/modal/ModalBody'
import ModalHeader from '@shared/components/modal/ModalHeader'

import { staticRoutes } from '@routing/routes'
import { FUNDING_FLOWS } from '@common/constants'
import useFundingFlowRouteGuard from '@common/utils/useFundingFlowRouteGuard'
import { gql, useQuery } from '@services/serviceUtils'

import styling from './transfer.module.scss'

const REQUIRED_FUNDING_FLOWS = [FUNDING_FLOWS.BASIC, FUNDING_FLOWS.TRANSFER]

const readinessForMoneyTransferQuery = gql`
  query ReadinessForMoneyTransfer {
    readinessForMoneyTransfer {
      message
      canInitiateTransfer
    }
  }
`

const Transfer = () => {
  const { state } = useLocation()
  const navigate = useNavigate()

  useFundingFlowRouteGuard({ requiredFundingFlows: REQUIRED_FUNDING_FLOWS })

  const [modalMessage, setModalMessage] = useState('')
  const [showNotAbleToTransferModal, toggleShowNotAbleToTransferModal] = useState(false)

  useQuery(readinessForMoneyTransferQuery, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (!data?.readinessForMoneyTransfer?.canInitiateTransfer) {
        setModalMessage(data?.readinessForMoneyTransfer?.message)
        toggleShowNotAbleToTransferModal(true)
      }
    },
  })

  return (
    <>
    <div className='white-card-container'>
      <GreenwoodLogo className='logo' />
      <Routes>
        <Route path={staticRoutes.transferForm.relativePathname} element={<ProtectedRoute component={TransferForm} />} />
        <Route path={staticRoutes.transferReview.relativePathname} element={<ProtectedRoute component={TransferReview} />} />
        <Route path={staticRoutes.transferSuccess.relativePathname} element={<ProtectedRoute component={TransferSuccess} />} />
        <Route
          path='*'
          element={
            <Navigate to={staticRoutes.transferForm.pathname} state={state} />
          }
        />
      </Routes>
    </div>
    <Modal
      centered
      size='md'
      open={showNotAbleToTransferModal}
      modalContentClassName={styling['transfer-money-modal-content']}
      toggleModal={() => toggleShowNotAbleToTransferModal(!showNotAbleToTransferModal)}
      closeOnBackdropClick={false}
    >
      <ModalHeader className={styling['transfer-money-modal-header']}>
        <h4 data-cy='transfer-money-not-able-to-transfer-modal-header'>Transfer Money</h4>
      </ModalHeader>
      <ModalBody className={styling['transfer-money-modal-body']}>
        <div>
          <p>{modalMessage}</p>
          <Button
            data-cy='transfer-money-not-able-to-transfer-modal-okay-button'
            onClick={() => {
              toggleShowNotAbleToTransferModal(false)
              navigate(staticRoutes.dashboard.pathname)
            }}
          >
            Okay
          </Button>
        </div>
      </ModalBody>
    </Modal>
  </>
  )
}

export default Transfer
