import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Navigate, useOutletContext, useParams } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Navigation } from 'swiper/modules'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'

import Button from '@shared/components/button/Button'

import { MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'
import { staticRoutes } from '@routing/routes'
import { BankingContainerContext } from '@common/components/v2/bankingContainer/BankingContainer'

import styling from './cashbackRetailer.module.scss'

const truncatedTextMaxLength = 330

const CashbackRetailer = () => {
  const [swiper, setSwiper] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const [showFullText, setShowFullText] = useState(false)

  const nextButtonRef = useRef(null)
  const prevButtonRef = useRef(null)

  const isTablet = useMediaQuery({ query: `(max-width: ${MAX_WIDTH_BREAKPOINTS.MEDIUM_LARGE}px)` })

  const { retailer } = useParams()

  const { title, setTitle, cashbackRetailersData } = useOutletContext()

  const { theme } = useContext(BankingContainerContext)

  const offerDetails = useMemo(() => (
    cashbackRetailersData?.find(offer => offer.key === retailer) || {}
  ), [retailer, cashbackRetailersData])

  const backgroundText = useMemo(() => {
    const text = offerDetails?.details

    if (text?.length < truncatedTextMaxLength) {
      setShowFullText(true)

      return text
    }

    return showFullText ? text : `${text?.substring(0, truncatedTextMaxLength)}...`
  }, [offerDetails, showFullText])

  useEffect(() => {
    setTitle(offerDetails?.name)
  }, [offerDetails, setTitle])

  return (
    <>
      {!Object.keys(offerDetails)?.length ? (
        <Navigate to={staticRoutes.cashback.pathname} />
      ) : (
        <div className={classNames(styling['cashback-retailer-container'], styling[theme])}>
          <div className={styling['image-container']}>
            <Swiper
              modules={[A11y, Navigation]}
              a11y
              slidesPerView={1}
              className={styling.swiper}
              direction='horizontal'
              onSwiper={setSwiper}
              onSlideChangeTransitionEnd={swiper => setActiveIndex(swiper.activeIndex)}
            >
              {offerDetails?.images?.map((image, index) => (
                <SwiperSlide key={`${offerDetails?.key}-image-${index}`} className={styling['image-slide']}>
                  {image}
                </SwiperSlide>
              ))}
            </Swiper>
            {offerDetails?.images?.length === 2 ? (
              <div className={styling.controls}>
                <span
                  ref={prevButtonRef}
                  onClick={() => {
                    swiper?.slidePrev()
                  }}
                  className={activeIndex === 0 ? styling.active : ''}
                  role='button'
                />
                <span
                  ref={nextButtonRef}
                  onClick={() => {
                    swiper?.slideNext()
                  }}
                  className={activeIndex === 1 ? styling.active : ''}
                  role='button'
                />
              </div>
            ) : null}
          </div>
          <div className={styling['content-container']}>
            {isTablet ? <h3>{title}</h3> : null}
            <div className={styling['reward-details-container']}>
              <div className={styling['reward-details-text']}>
                <p>10% Cash Back {isTablet ? '' : 'Reward'}</p>
                <p className={styling.subtext}>Terms and conditions apply</p>
                <p>Cash Back for Buying Black™ by Greenwood</p>
              </div>
              <div className={styling['logo-container']}><GreenwoodLogo /></div>
            </div>
            <div className={classNames(styling['retailer-content'], styling['retailer-background-details'])}>
              {backgroundText}{' '}
              {offerDetails?.details?.length > truncatedTextMaxLength ? (
                <span role='button' onClick={() => setShowFullText(!showFullText)}>
                  {showFullText ? 'Less' : 'More'}
                </span>
              ) : null}
            </div>
            <div className={styling['retailer-content']}>
              <h4>Offer Terms</h4>
              <p>
                {offerDetails?.offerDescription}
              </p>
            </div>
            <div className={styling['retailer-content']}>
              <h4>Info</h4>
              <div className={styling['contact-details-container']}>
                <div className={styling.detail}>
                  <span>Phone</span>
                  <span>{offerDetails?.phone}</span>
                </div>
                <div className={styling.detail}>
                  <span>Address</span>
                  <span>{offerDetails?.address}</span>
                </div>
              </div>
            </div>
            <Button
              className={styling.button}
              onClick={() => window.open(offerDetails?.url)}
            >
              Visit Website
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default CashbackRetailer
