import React from 'react'
import classNames from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y } from 'swiper/modules'

import TravelInStyleImg from '@common/images/benefits/travel-in-style.png'
import DoorDashImg from '@common/images/benefits/doordash.png'
import LyftOnUsImg from '@common/images/benefits/lyft-on-us.jpeg'

import { BENEFITS } from '@common/constants/benefitsText'

import 'swiper/scss'
import 'swiper/scss/a11y'

import styling from './benefitCards.module.scss'

const cards = [
  {
    color: 'green',
    img: TravelInStyleImg,
    ...BENEFITS.TRAVEL_IN_STYLE,
  }, {
    color: 'yellow',
    img: DoorDashImg,
    ...BENEFITS.DOORDASH,
  }, {
    color: 'blue',
    img: LyftOnUsImg,
    ...BENEFITS.LYFT_ON_US,
  },
]

const BenefitCards = () => {
  const getCards = () => {
    return cards.map((card, index) => {
      const cardClasses = classNames(styling.card, styling[`color-${card.color}`])

      return (
        <SwiperSlide key={index} className={styling.slide}>
          <div className={cardClasses}>
            <img alt={card.title} src={card.img} />
            <h3>{card.title}</h3>
            <p className={styling['card-paragraph-text']}>{card.subtext}</p>
          </div>
        </SwiperSlide>
      )
    })
  }

  return (
    <Swiper
      modules={[A11y]}
      a11y
      className={styling['benefit-cards-container']}
    >
      {getCards()}
    </Swiper>
  )
}

export default BenefitCards
