import React, { useMemo, useState } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { MdMessage } from 'react-icons/md'
import { FaRegWindowMinimize } from 'react-icons/fa'

import { getFive9ChatUrl } from '@common/constants/five9ChatConstants'

import styling from './five9ChatBox.module.scss'

const Five9ChatBox = () => {
  const [isFive9ChatBoxOpen, setFive9ChatBoxOpen] = useState(false)

  const userId = useSelector(state => state?.application?.userId)

  const chatUrl = useMemo(() => getFive9ChatUrl(userId), [userId])

  return (
    <div className={classNames(styling['five9-chat-box-container'], isFive9ChatBoxOpen && styling.open)}>
      <div
        role='button'
        className={styling['min-max-button']}
        onClick={() => {
          if (!isFive9ChatBoxOpen) {
            setFive9ChatBoxOpen(true)
          }
        }}
      >
        <div><MdMessage /> <span>Chat</span></div>
        {isFive9ChatBoxOpen && (
          <div className={styling['min-max-btn-container']}>
            <FaRegWindowMinimize
              className={classNames(styling.button, styling.minimize)}
              role='button'
              onClick={() => {
                setFive9ChatBoxOpen(false)
              }}
            />
          </div>
        )}
      </div>
      <div className={classNames(styling['content-container'], { [styling.hide]: !isFive9ChatBoxOpen })}>
        <iframe
          src={chatUrl}
          title='Five9 Chat Box'
        />
      </div>
    </div>
  )
}

export default Five9ChatBox
