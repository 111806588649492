import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import MaskedInput from 'react-text-mask'
import { parse, endOfMonth, isAfter, format, isValid } from 'date-fns'
import forge from 'node-forge'

import { ADD_DEBIT_CARD_FLOW_TYPES, CVV_VALIDATION_SCHEMA } from '@common/constants'
import { staticRoutes } from '@routing/routes'
import { gql, useMutation, useQuery } from '@services/serviceUtils'
import { addAlertAction } from '@redux/alerts/alertsActions'
import { ALERT_TYPES, DEFAULT_ALERT_DISMISS_DELAY } from '@shared/constants/uiConstants'
import { base64ToBase64UrlSafe, base64UrlSafeToBase64 } from '@shared/utils'
import { getDisplayName } from '@common/utils/accountUtils'
import { removeSpaces } from '@common/utils/cardUtils'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import { ReactComponent as Eye } from '@shared/images/icons/eye.svg'
import { ReactComponent as EyeClosed } from '@shared/images/icons/eye-closed.svg'

import Button from '@shared/components/button/Button'
import FormInput from '@shared/components/formInput/FormInput'
import FormInputAddon from '@shared/components/formInput/FormInputAddon'

import styling from './addDebitCard.module.scss'

const encryptionKeyQuery = gql`
  query EncryptionKey {
    encryptionKey {
      keyId
      key
    }
  }
`

const addLinkedCardMutation = gql`
  mutation AddLinkedCard($linkedCardInputDTOInput: LinkedCardInputDTOInput!) {
    addLinkedCard(linkedCardInputDTOInput: $linkedCardInputDTOInput) {
      id
    }
  }
`

const debitCardFormValidationSchema = Yup.object().shape({
  ...CVV_VALIDATION_SCHEMA,
  cardNumber: Yup.string()
    .matches(/^[\d ]*$/, 'Card Number must be a number')
    .required('Card Number is a required field')
    .test('card number length', 'Card Number must be between 13 and 19 digits', value => {
      const numStr = removeSpaces(value)
      return numStr?.length >= 13 && numStr?.length <= 19
    }),
  expiration: Yup.string()
    .required('Expiration is a required field')
    .test('valid date', 'Expiration is invalid', value => {
      const now = new Date()
      const expirationDate = endOfMonth(parse(value, 'MM/yy', now))
      const isExpired = isAfter(now, expirationDate)

      return isValid(expirationDate) && !isExpired
    })
    .test('expiration length', 'Expiration date must be in the format MM/YY', value => {
      return value?.split('/').join('').length === 4
    }),
})

const AddDebitCard = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const [showCardNumber, setShowCardNumber] = useState(true)
  const dispatch = useDispatch()
  const customer = useSelector(state => state.customer)

  const {
    firstName,
    lastName,
    deliveryAddress,
    buildingNumber,
    postalCode,
    locality,
    subdivisions,
  } = customer

  const customerFullName = getDisplayName({ firstName, lastName })

  const addDebitCardFlow = state?.addDebitCardFlow
  const isDashboardQuickAction = state?.isDashboardQuickAction

  const { data: encryptionKeyData, loading: encryptionKeyLoading } = useQuery(encryptionKeyQuery, {
    fetchPolicy: 'no-cache',
  })

  const [submitAddLinkedCardRequest] = useMutation(addLinkedCardMutation)

  const addrState = subdivisions?.length ? subdivisions[0] : ''
  const customerAddress = `${deliveryAddress}, ${
    buildingNumber ? `${buildingNumber}, ` : ''
  } ${locality}, ${addrState ? `${addrState}, ` : ''} ${postalCode}`

  const handleCancelClick = () => {
    switch (addDebitCardFlow) {
      case ADD_DEBIT_CARD_FLOW_TYPES.BEFORE_DEPOSIT:
        navigate(isDashboardQuickAction ? staticRoutes.dashboard.pathname : staticRoutes.moveMoneyAddMoney.pathname, { state })
        break
      case ADD_DEBIT_CARD_FLOW_TYPES.BEFORE_FUNDING:
        navigate(staticRoutes.accountsForFunding.pathname, { state })
        break
      case ADD_DEBIT_CARD_FLOW_TYPES.FROM_DEPOSIT_FORM:
        navigate(staticRoutes.debitCardDeposit.pathname, { state })
        break
      default:
        navigate(staticRoutes.settingsLinkedAccounts.pathname)
        break
    }
  }

  const handleSubmit = async values => {
    const { cardNumber, expiration, cvv } = values

    const cardNumStr = removeSpaces(cardNumber)
    const formattedDate = format(parse(expiration, 'MM/yy', new Date()), 'yyyyMM')
    const cardText = `${cardNumStr}|${formattedDate}|${cvv}`

    const convertedKey = base64UrlSafeToBase64(encryptionKeyData?.encryptionKey?.key)

    const keyData = `-----BEGIN PUBLIC KEY-----${convertedKey}-----END PUBLIC KEY-----`
    const publicKey = forge.pki.publicKeyFromPem(keyData)

    const encryptedData = publicKey.encrypt(cardText, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
      mgf1: forge.md.sha1.create(),
    })

    const urlSafeEncryptedData = base64ToBase64UrlSafe(forge.util.encode64(encryptedData))

    const response = await submitAddLinkedCardRequest({
      variables: {
        linkedCardInputDTOInput: {
          encryptedCardData: urlSafeEncryptedData,
          keyId: encryptionKeyData?.encryptionKey?.keyId,
          cardTypeCode: parseInt(cardNumber?.slice(0, 1)),
          address: {
            addr1: deliveryAddress,
            addr2: buildingNumber,
            city: locality,
            state: addrState,
            zip: postalCode,
          },
        },
      },
    })

    const data = response?.data

    if (data?.addLinkedCard?.id) {
      dispatch(
        addAlertAction({
          text: 'You have successfully added your card!',
          type: ALERT_TYPES.SUCCESS,
          dismissible: false,
          autoDismissDelay: DEFAULT_ALERT_DISMISS_DELAY,
        })
      )
      if (!addDebitCardFlow) {
        navigate(staticRoutes.settingsLinkedAccounts.pathname)
      } else {
        navigate(staticRoutes.debitCardDeposit.pathname, { state })
      }
    }
  }

  return (
    <div className={classNames('white-card-container', styling['add-debit-card-container'])}>
      <GreenwoodLogo className='logo' />
      <h1>Add a Debit Card</h1>
      <p>Your information is protected by 256-bit encryption and SSL technology.</p>
      <div className={styling['uneditable-field-container']}>
        <div className={styling.header}>Name on Card:</div>
        <div data-cy='add-debit-card-customer-full-name'>{customerFullName}</div>
      </div>
      <div className={styling['uneditable-field-container']}>
        <div className={styling.header}>Address:</div>
        <div data-cy='add-debit-card-customer-full-name'>{customerAddress}</div>
      </div>
      <Formik
        initialValues={{
          cardNumber: '',
          expiration: '',
          cvv: '',
        }}
        validationSchema={debitCardFormValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form data-cy='add-debit-card-form'>
            <Field name='cardNumber'>
              {({ field }) => (
                <MaskedInput
                  {...field}
                  mask={[
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  guide={false}
                  disabled={isSubmitting}
                  render={(ref, props) => (
                    <FormInput
                      label='Card Number'
                      required
                      type={showCardNumber ? 'text' : 'password'}
                      invalid={errors.cardNumber && touched.cardNumber}
                      errorText={errors.cardNumber}
                      innerRef={ref}
                      {...props}
                    >
                      <FormInputAddon type='trailing'>
                        <div
                          className='icon-container'
                          onClick={() => setShowCardNumber(!showCardNumber)}
                        >
                          {showCardNumber ? <EyeClosed /> : <Eye />}
                        </div>
                      </FormInputAddon>
                    </FormInput>
                  )}
                />
              )}
            </Field>
            <div className={styling['two-field-input-container']}>
              <Field name='expiration'>
                {({ field }) => (
                  <MaskedInput
                    {...field}
                    mask={[/\d/, /\d/, '/', /\d/, /\d/]}
                    guide={false}
                    disabled={isSubmitting}
                    render={(ref, props) => (
                      <FormInput
                        required
                        invalid={errors.expiration && touched.expiration}
                        errorText={errors.expiration}
                        autoComplete='off'
                        label='Expiration'
                        placeholder='MM/YY'
                        innerRef={ref}
                        {...props}
                      />
                    )}
                  />
                )}
              </Field>
              <Field name='cvv'>
                {({ field }) => (
                  <MaskedInput
                    {...field}
                    mask={[/\d/, /\d/, /\d/]}
                    guide={false}
                    disabled={isSubmitting}
                    render={(ref, props) => (
                      <FormInput
                        required
                        invalid={errors.cvv && touched.cvv}
                        errorText={errors.cvv}
                        autoComplete='off'
                        label='CVV'
                        innerRef={ref}
                        {...props}
                      />
                    )}
                  />
                )}
              </Field>
            </div>
            <Button
              type='submit'
              isLoading={isSubmitting}
              className={styling['add-card-button']}
              disabled={isSubmitting || encryptionKeyLoading}
            >
              Add Card
            </Button>
            <Button
              ghost
              onClick={handleCancelClick}
              type='button'
              className='additional-button'
              data-cy='add-debit-card-cancel-button'
            >
              Cancel
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default AddDebitCard
