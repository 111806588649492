export const getFive9ChatOptions = userId => ({
  rootUrl: 'https://app.five9.com/consoles/',
  type: 'chat',
  title: 'Greenwood Community Support',
  tenant: 'Greenwood',
  profiles: 'Tier 1',
  showProfiles: false,
  autostart: true,
  theme: 'high-contrast.css',
  surveyOptions: {
    showComment: true,
    requireComment: false,
  },
  fields: {
    name: {
      value: '',
      show: true,
      label: 'Name',
    },
    email: {
      value: '',
      show: true,
      label: 'Email',
    },
    userId: {
      value: userId,
      show: false,
      label: 'UserId',
    },
    UserLocale: {
      value: 'en',
      show: false,
    },
  },
  playSoundOnMessage: true,
  allowCustomerToControlSoundPlay: true,
  showEmailButton: true,
  hideDuringAfterHours: false,
  useBusinessHours: false,
  showPrintButton: false,
  allowUsabilityMenu: false,
  enableCallback: false,
  callbackList: '',
  allowRequestLiveAgent: false,
})

export const getFive9ChatUrl = userId => `https://app.five9.com/consoles/ChatConsole/index.html?title=Greenwood%20Community%20Support&tenant=Greenwood&profiles=Tier%201&showProfiles=false&autostart=true&theme=high-contrast.css&logo=&surveyOptions=%7B%22showComment%22%3Atrue%2C%22requireComment%22%3Afalse%7D&fields=%7B%22name%22%3A%7B%22value%22%3A%22%22%2C%22show%22%3Atrue%2C%22label%22%3A%22Name%22%7D%2C%22email%22%3A%7B%22value%22%3A%22%22%2C%22show%22%3Atrue%2C%22label%22%3A%22Email%22%7D%2C%22userId%22%3A%7B%22value%22%3A%22${userId}%22%2C%22show%22%3Afalse%2C%22label%22%3A%22UserId%22%2C%22required%22%3Afalse%7D%2C%22UserLocale%22%3A%7B%22value%22%3A%22en%22%2C%22show%22%3Afalse%7D%7D&playSoundOnMessage=true&allowCustomerToControlSoundPlay=true&showEmailButton=true&useBusinessHours=false&showPrintButton=false&allowUsabilityMenu=false&enableCallback=false&callbackList=&allowRequestLiveAgent=false`
