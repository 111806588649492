import React from 'react'
import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'
import { useNavigate, useOutletContext } from 'react-router-dom'

import FaqCallout from '../faqCallout/FaqCallout'
import CashbackOfferCard from '../cashbackOfferCard/CashbackOfferCard'

import { MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'
import { staticRoutes } from '@routing/routes'

import styling from './cashbackOffers.module.scss'

const CashbackOffers = () => {
  const isTablet = useMediaQuery({ query: `(max-width: ${MAX_WIDTH_BREAKPOINTS.MEDIUM_LARGE}px)` })

  const navigate = useNavigate()

  const { cashbackRetailersData } = useOutletContext()

  return (
    <div className={styling['offers-container']}>
      {isTablet && (
        <p>
          The inaugural group of businesses in our Cash Back for Buying Black program are true pillars in{' '}
          the Atlanta community. As a Greenwood Premium member, you can now earn 10% cash back on any in-store{' '}
          purchase when using your Greenwood Mastercard Debit Card. It's a win-win: eat local, support Black-owned{' '}
          businesses, and help fuel the recirculation of the Black dollar!
        </p>
      )}
      <h3 className={classNames('v2-header', styling.header)}>Top Offers</h3>
      <div className={styling.offers}>
        {cashbackRetailersData?.map((item, index) => (
          <CashbackOfferCard
            className={styling['offer-card']}
            header={item.name}
            key={`${item.key}-${index}`}
            subtext={item.details}
            logo={item.logo}
            style={{ backgroundImage: `${item?.background?.color}, url(${item?.background?.image})` }}
            handleClick={() => navigate(`${staticRoutes.cashback.pathname}/${item.key}`)}
          />
        ))}
        {!isTablet ? <FaqCallout /> : null}
      </div>
    </div>
  )
}

export default CashbackOffers
